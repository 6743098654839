import { CartFragmentFragment, GetCustomerDataQuery } from '~/operations';

export enum CheckoutPaymentMethod {
  PAYMENT_SERVICE = 'payment_service_credit_card',
  DEFAULT = 'ziyou_braspag',
}

export interface CheckoutContextProviderProps {
  data?: GetCustomerDataQuery;
}

export type CreditCardTokenData = {
  type: 'token';
  id: string;
  issuer?: string;
  holder?: string;
  lastFour?: string;
  expiration?: string;
  isDefault: boolean;
};

export type CreditCardData = {
  type: 'card';
  number: string;
  holder: string;
  expiration: string;
  cvc: string;
  issuer: string;
};

export type CardData = CreditCardTokenData | CreditCardData;

export interface CheckoutContextInterface {
  availableCreditCards: CreditCardTokenData[];
  cart?: CartFragmentFragment;
  paymentMethod?: CheckoutPaymentMethod;
  freeOrderCheckout: boolean;
}
