import { Alert } from '@objectedge/ziyou-storefront-ds';
import { useAlertsContext } from '~/utils/useAlerts';
import styles from './Alerts.module.scss';

export const Alerts = () => {
  const { state: alerts, actions: alertsActions } = useAlertsContext();

  const handleCloseAlert = (alertId) => {
    alertsActions.removeAlert(alertId);
  };

  return (
    <div className={styles['alert-container']}>
      {Object.values(alerts).map(({ color, id, text, className }) => (
        <Alert key={id} color={color} className={className} closeCallback={() => handleCloseAlert(id)}>
          {text}
        </Alert>
      ))}
    </div>
  );
};

export default Alerts;
