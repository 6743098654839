import { createContext } from 'react';
import { ProductInterface } from '~/operations';
import { ProductPlanOption } from '~/utils/product';

export interface ProductDetailsPageContextInterface {
  variant?: Partial<ProductInterface | undefined | null>;
  plan?: ProductPlanOption;

  selectVariant: (any) => void;
  selectPlan: (any) => void;
}

export const ProductDetailsPageContext = createContext<ProductDetailsPageContextInterface>({
  selectVariant: () => {},
  selectPlan: () => {},
});
