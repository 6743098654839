import { createContext } from 'react';
import { Customer, CustomerSubscription, PartnerApp } from '~/operations';

interface SubscriptionContextInteface {
  customer?: Partial<Customer>;
  subscription?: Partial<CustomerSubscription>;
  partnerApps?: PartnerApp[];
}

export const SubscriptionContext = createContext<SubscriptionContextInteface>({});
