import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import cookie from 'js-cookie';
import { useMemo } from 'react';
import cache from '~/state/cache';
import { bugsnagMiddleware } from './bugsnag';
import { CookiesActions, useCookiesContext } from '~/utils/useCookies';

let apolloClient: ApolloClient<InMemoryCache | NormalizedCacheObject>;

const httpLink = new HttpLink({
  uri: process.browser ? process.env.NEXT_PUBLIC_GRAPHQL_URL : process.env.GRAPHQL_URL,
  credentials: 'same-origin',
});
const persistedQueriesLink = createPersistedQueryLink({ useGETForHashedQueries: true });
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = cookie.get(process.env.AUTH_COOKIE_KEY as string);
  if (token) {
    // add the authorization to the headers
    operation.setContext({ headers: { authorization: `Bearer ${token}` } });
  }

  return forward(operation);
});

function createApolloClient(cookieActions: CookiesActions | null = null): ApolloClient<NormalizedCacheObject> {
  const errors = onError(({ graphQLErrors }) => {
    if (!graphQLErrors) return;

    const authenticationError = graphQLErrors.find(
      (errors) => errors.message === 'Not Authorised!' || errors.message === `The current customer isn't authorized.`
    );
    if (cookieActions && authenticationError) {
      cookieActions.updateUserTokenCookie('Not Authorized');
    }
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([errors, bugsnagMiddleware, authMiddleware, persistedQueriesLink as any, httpLink]),
    connectToDevTools: !!process.env.APOLLO_DEV_TOOLS || false,
    cache,
  });
}

export function initializeApollo(
  initialState: any = null,
  cookieActions: CookiesActions | null = null
): ApolloClient<InMemoryCache | NormalizedCacheObject> {
  const _apolloClient = createApolloClient(cookieActions);

  // If the page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any): ApolloClient<InMemoryCache | NormalizedCacheObject> {
  const { actions: cookieActions } = useCookiesContext();
  const store = useMemo(() => initializeApollo(initialState, cookieActions), [initialState]);
  return store;
}
