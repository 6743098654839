import { ApolloProvider } from '@apollo/client';
import { useApollo } from '~/connectors/apollo';

export const CustomApolloProvider: React.FC<{ initialState: any }> = ({ children, initialState }) => {
  const apolloClient = useApollo(initialState);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default CustomApolloProvider;
