import { ProductInterface, SelectedCustomizableOption } from '~/operations';

export interface ProductPrices {
  fromPrice?: number;
  listPrice: number;
  salePrice?: number;
  discount?: number;
  discountTag?: string;
  plans?: ProductPlans;
}

export interface ProductPlans {
  id: number;
  options: Array<ProductPlanOption>;
}

export interface ProductPlanOption {
  optionId: number;
  id: number;
  price: number;
  duration: string;
  discount: number;
  discountPct: number;
}

export interface MinimumRequirement {
  label: string;
  value: string;
}

export interface ParseMinimumRequirementsSettings {
  cleanSizes?: boolean;
}

export const getPrices = (product: Partial<ProductInterface>): ProductPrices => {
  const min = [
    product.price_range?.minimum_price?.final_price.value,
    product.price_range?.minimum_price?.regular_price.value,
  ];
  const max = [
    product.price_range?.maximum_price?.final_price.value,
    product.price_range?.maximum_price?.regular_price.value,
  ];

  let fromPrice;
  if (max[0] !== min[0]) fromPrice = min[0];

  const listPrice = max[1] || 0;
  const salePrice = min[0] && listPrice !== min[0] ? min[0] : undefined;
  const discount = (listPrice && salePrice && listPrice - salePrice) || undefined;
  const discountTag =
    listPrice && discount && discount > 0 ? `${Math.floor((discount / listPrice) * 100)}% off` : undefined;

  let plans: ProductPlans | undefined = undefined;
  const availablePlans = (product as any).options?.find((o) => o.title === 'Plano');
  if (availablePlans) {
    plans = {
      id: availablePlans.option_id,
      options: availablePlans.value
        .filter((v) => v.title.match(/^\d+m$/) && v.price < 0)
        .map((v) => {
          const origPrice = salePrice ? salePrice : listPrice;
          const plan = {
            optionId: availablePlans.option_id,
            id: v.option_type_id,
            price: origPrice + (v.price_type === 'FIXED' ? v.price : origPrice * (v.price / 100)),
            duration: v.title.replace('m', ''),
            discount: 0,
            discountPct: 0,
          };
          plan.discount = origPrice - plan.price;
          plan.discountPct = (plan.discount / origPrice) * 100;
          return plan;
        }),
    };
  }

  return { fromPrice, listPrice, salePrice, discount, discountTag, plans };
};

/**
 * Parse the minimum requirements json into a list of {label, value}
 */
export const parseMinimumRequirements = (
  requirements?: string | null,
  { cleanSizes = false }: ParseMinimumRequirementsSettings = {}
): Array<MinimumRequirement> => {
  if (!requirements || typeof requirements !== 'string') return [];

  let cleanString = requirements.replace(/[“”]/g, '"');
  if (cleanSizes) cleanString = cleanString.replace(/\([LCA]\)/g, '');

  try {
    const parsedRequirements = cleanString ? JSON.parse(cleanString) : {};
    return Object.entries(parsedRequirements).map(([label, value]) => ({
      label: label as string,
      value: value as string,
    }));
  } catch (e) {
    return [];
  }
};

export const getSubscriptionPlanValue = (customizable_options: Array<SelectedCustomizableOption>) => {
  const availablePlan = customizable_options.find((obj) => obj.label === 'Plano');
  return availablePlan ? `Assinatura mínima ${availablePlan.values?.[0]?.label?.replace('m', ' meses')}` : '';
};

export default parseMinimumRequirements;
