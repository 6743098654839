const sleep = (delay) => new Promise((res) => setTimeout(res, delay));

const fixWhatsappIcon = async (url: string) => {
  //   return;
  if (typeof document === 'undefined') return;

  let bot;
  let tries = 0;
  while (!bot) {
    bot = document.getElementById('bot');
    if (!bot) {
      if (tries++ === 3) return; // after third time let it go
      await sleep(1000); // wait 3x for 1s for the element appear in the screen
    }
  }
  // on pdp and checkout, move whatsapp-icon higher so it does not overlap with add-to-cart
  if (url.startsWith('/produto') || url.startsWith('/checkout')) {
    bot.classList.add('whatsapp-icon-above');
    bot.classList.remove('whatsapp-icon-below');
  } else {
    bot.classList.add('whatsapp-icon-below');
    bot.classList.remove('whatsapp-icon-above');
  }
  // Hide from pages different from pdp, search/plp and home
  bot.style.visibility = url.startsWith('/checkout/') ? 'hidden' : 'unset';

  // on pdp, move it higher so it does not overlap with add-to-cart
  bot.style['margin-bottom'] = url.startsWith('/produto') ? '75px' : '0px';
};

export default fixWhatsappIcon;
