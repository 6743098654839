export var Size;
(function(Size) {
    Size["SM"] = "sm";
    Size["LG"] = "lg";
})(Size || (Size = {}));
export var Voltage;
(function(Voltage) {
    Voltage["V_110"] = "110v";
    Voltage["V_220"] = "220v";
})(Voltage || (Voltage = {}));
