import { InMemoryCache } from '@apollo/client';
import { deepCloneState } from '~/utils/deepCloneState';

const mergeProduct = (existing: any, incoming: any) => {
  const items = existing?.items || [];
  const stateCloned = deepCloneState(incoming);
  stateCloned.items = [...items, ...stateCloned.items];

  return stateCloned;
};

const mergeOpenSlots = (existing: any, incoming: any) => {
  const items = existing?.daysWithOpenSlots || [];
  incoming.daysWithOpenSlots = [...items, ...incoming.daysWithOpenSlots];

  return incoming;
};

const typePolicies = {
  Query: {
    fields: {
      products: {
        keyArgs: ['search'],
        merge: mergeProduct,
      },
      productsByUrlKey: {
        keyArgs: ['urlKey'],
      },
      openSlots: {
        keyArgs: ['ownerId'],
        merge: mergeOpenSlots,
      },
      cart: {
        keyArgs: ['cart_id'],
      },
      getPostalCodeDetails: {
        keyArgs: ['postalCode'],
      },
    },
  },
  CategoryTree: {
    fields: {
      products: {
        keyArgs: [],
      },
    },
  },
  Cart: {
    fields: {
      items: {
        read: (item) => {
          // TODO We have an issue in magento that makes unavailable products return a null object
          // as the first of the product items. This is a workaround to fix this issue
          return item?.filter((i) => !!i);
        },
      },
    },
  },
  PaymentToken: {
    keyFields: ['public_hash'],
  },
  SelectedCustomizableOption: {
    keyFields: ['values'],
  },
  CustomizableOptionInterface: {
    keyFields: ['option_id'],
  },
  CustomizableRadioValue: {
    keyFields: ['option_type_id'],
  },
};

const possibleTypes = {
  CartAddressInterface: ['BillingCartAddress', 'ShippingCartAddress'],
  CartItemInterface: [
    'SimpleCartItem',
    'VirtualCartItem',
    'DownloadableCartItem',
    'BundleCartItem',
    'ConfigurableCartItem',
  ],
  ProductInterface: [
    'VirtualProduct',
    'SimpleProduct',
    'DownloadableProduct',
    'BundleProduct',
    'GroupedProduct',
    'ConfigurableProduct',
  ],
  CategoryInterface: ['CategoryTree'],
  MediaGalleryInterface: ['ProductImage', 'ProductVideo'],
  ProductLinksInterface: ['ProductLinks'],
  AggregationOptionInterface: ['AggregationOption'],
  LayerFilterItemInterface: ['LayerFilterItem', 'SwatchLayerFilterItem'],
  PhysicalProductInterface: ['SimpleProduct', 'BundleProduct', 'GroupedProduct', 'ConfigurableProduct'],
  CustomizableOptionInterface: [
    'CustomizableAreaOption',
    'CustomizableDateOption',
    'CustomizableDropDownOption',
    'CustomizableMultipleOption',
    'CustomizableFieldOption',
    'CustomizableFileOption',
    'CustomizableRadioOption',
    'CustomizableCheckboxOption',
  ],
  CustomizableProductInterface: [
    'VirtualProduct',
    'SimpleProduct',
    'DownloadableProduct',
    'BundleProduct',
    'ConfigurableProduct',
  ],
  SwatchDataInterface: ['ImageSwatchData', 'TextSwatchData', 'ColorSwatchData'],
  SwatchLayerFilterItemInterface: ['SwatchLayerFilterItem'],
  BasePayload: [
    'InactivateUserMutationPayload',
    'LoginMutationPayload',
    'RegisterMutationPayload',
    'ResetPasswordMutationPayload',
    'SendResetPasswordMutationPayload',
    'UpdateUserMutationPayload',
  ],
  Profile: ['ZiyouStoreProfile'],
  BasePayloadScheduling: [
    'CalendarMutationPayload',
    'RemoveAppointmentPayload',
    'ScheduleAppointmentPayload',
    'UpdateAppointmentStatusPayload',
  ],
  BasePayloadLocation: ['CleanUpStaleDataPayload', 'ImportDataPayload'],
  Node: ['Address'],
};

export default new InMemoryCache({ typePolicies, possibleTypes });
