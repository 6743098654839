import { createContext, useContext, useEffect, useReducer } from 'react';

type Alert = {
  id: number | string;
  text: string;
  className?: string;
  color: 'primary' | 'success' | 'danger' | 'warning';
};

type InitialState = Record<string | number, Alert>;

type AddAlertOptions = {
  id?: number | string;
  className?: string;
};

type Actions = {
  addSuccessAlert: (text: string, options?: AddAlertOptions) => void;
  addDangerAlert: (text: string, options?: AddAlertOptions) => void;
  addWarningAlert: (text: string, options?: AddAlertOptions) => void;
  removeAlert: (id: number | string) => void;
};

type ReducerActionTypes =
  | {
      type: 'ADD_ALERT';
      payload: Alert;
    }
  | {
      type: 'REMOVE_ALERT';
      payload: number | string;
    };

const initialState: InitialState = {};

const reducer = (state: InitialState = initialState, action: ReducerActionTypes) => {
  switch (action.type) {
    case 'ADD_ALERT': {
      return { ...state, [action.payload.id]: action.payload };
    }
    case 'REMOVE_ALERT': {
      if (state[action.payload]) {
        const stateClone = { ...state };

        delete stateClone[action.payload];

        return stateClone;
      } else {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};

export const useAlerts = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    actions: {
      addSuccessAlert: (text: string, options?: AddAlertOptions) => {
        const { id = Date.now(), className } = options || {};
        if (state[id]) return;

        dispatch({
          type: 'ADD_ALERT',
          payload: {
            id,
            text,
            className,
            color: 'success',
          },
        });
        setTimeout(() => dispatch({ type: 'REMOVE_ALERT', payload: id }), 5000);
      },
      addDangerAlert: (text: string, options?: AddAlertOptions) => {
        const { id = Date.now(), className } = options || {};
        if (state[id]) return;

        dispatch({
          type: 'ADD_ALERT',
          payload: {
            id: id || Date.now(),
            text,
            className,
            color: 'danger',
          },
        });
        setTimeout(() => dispatch({ type: 'REMOVE_ALERT', payload: id }), 5000);
      },
      addWarningAlert: (text: string, options?: AddAlertOptions) => {
        const { id = Date.now(), className } = options || {};
        if (state[id]) return;

        dispatch({
          type: 'ADD_ALERT',
          payload: {
            id: id || Date.now(),
            text,
            className,
            color: 'warning',
          },
        });
        setTimeout(() => dispatch({ type: 'REMOVE_ALERT', payload: id }), 5000);
      },
      removeAlert: (id: number | string) => {
        dispatch({ type: 'REMOVE_ALERT', payload: id });
      },
    },
  };
};

export const AlertsContext = createContext<{ state: InitialState; actions: Actions }>({
  state: initialState,
  actions: {
    addSuccessAlert: (text: string, options?: AddAlertOptions) => {},
    addDangerAlert: (text: string, options?: AddAlertOptions) => {},
    addWarningAlert: (text: string, options?: AddAlertOptions) => {},
    removeAlert: (id: number | string) => {},
  },
});

export const useAlertsContext = () => useContext(AlertsContext);
