import { useApolloClient } from '@apollo/client';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useRefreshTokenMutation } from '~/operations';
import { useCartContext } from '~/utils/useCart';
import { useCookiesContext } from '~/utils/useCookies';

let refreshTokenTimeout;

export const AuthenticationController: React.FC = ({ children }) => {
  const Router = useRouter();
  const { cache } = useApolloClient();
  const { actions: cookieActions, state } = useCookiesContext();
  const { actions: cartActions } = useCartContext();
  const [refresh] = useRefreshTokenMutation();

  const refreshToken = useCallback(async () => {
    try {
      const { data } = await refresh();
      const token = data?.refreshCustomerToken?.token;
      if (token) {
        // reset timeout so we update token again with a new expire time
        refreshTokenTimeout = undefined;
        // Update token
        cookieActions.updateUserTokenCookie(token);
      }
    } catch (e) {
      // In case of any error refreshing token, remove cookies
      cookieActions.updateUserTokenCookie('');
    }
  }, []);

  useEffect(() => {
    if (!state.token) {
      // clear timeout in case of logout
      if (refreshTokenTimeout) refreshTokenTimeout = clearTimeout(refreshTokenTimeout);
      return;
    }

    if (state.token == 'Not Authorized') {
      cookieActions.updateUserTokenCookie('');
      cookieActions.updateUserEmail('');
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'customer' });
      cartActions.initCart(true);
      if (Router.pathname !== '/login') {
        Router.push('/login?from=' + window.location.pathname);
      }
      return;
    }

    const jwt = jwtDecode<JwtPayload>(state.token);
    if (jwt.exp && !refreshTokenTimeout) {
      // try to refresh 5m before expiration
      const timeout = jwt.exp * 1000 - Date.now() - 5 * 60000;
      refreshTokenTimeout = setTimeout(refreshToken, timeout > 300000 ? 300000 : timeout, jwt);
    }
  }, [state.token]);

  return <>{children}</>;
};

export default AuthenticationController;
