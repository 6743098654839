import { createContext, useMemo } from 'react';
import { CreditCardVault, PaymentToken } from '~/operations';
import {
  CheckoutContextInterface,
  CheckoutContextProviderProps,
  CheckoutPaymentMethod,
  CreditCardTokenData,
} from './checkout.types';

const mapPaymentTokenToCreditCardTokenData = (ct: PaymentToken): CreditCardTokenData => {
  const data: CreditCardTokenData = {
    type: 'token',
    id: ct.public_hash as string,
    isDefault: ct.isDefault || false,
  };
  try {
    const details = JSON.parse(ct.details || '{}');
    data.lastFour = details.Number.substr(-4);
    data.expiration = details.ExpirationDate;
    data.holder = details.Holder;
    data.issuer = details.type;
  } catch (e) {}

  return data;
};

const mapVaultToCreditCardTokenData = (ct: Partial<CreditCardVault>): CreditCardTokenData => ({
  type: 'token',
  id: ct.id as string,
  issuer: ct.issuer as string,
  holder: ct.holder as string,
  lastFour: ct.lastFour as string,
  expiration: ct.expirationDate as string,
  isDefault: ct.isDefault || false,
});

/* ---------------------- */

export const CheckoutContext = createContext<CheckoutContextInterface>({
  availableCreditCards: [],
  freeOrderCheckout: false,
});

export const CheckoutContextProvider: React.FC<CheckoutContextProviderProps> = ({ data, children }) => {
  const cart = data?.customerCart;

  const paymentMethod = useMemo(() => {
    return cart?.available_payment_methods?.some((pm) => pm?.code === CheckoutPaymentMethod.PAYMENT_SERVICE)
      ? CheckoutPaymentMethod.PAYMENT_SERVICE
      : CheckoutPaymentMethod.DEFAULT;
  }, [cart?.available_payment_methods]);

  const freeOrderCheckout = useMemo(() => cart?.prices?.grand_total === 0, [cart?.prices]);

  const availableCreditCards = useMemo(() => {
    if (paymentMethod === CheckoutPaymentMethod.PAYMENT_SERVICE) {
      return (data?.creditCardTokens || []).map(mapVaultToCreditCardTokenData);
    }
    if (paymentMethod === CheckoutPaymentMethod.DEFAULT) {
      return ((data?.customerPaymentTokens?.items as PaymentToken[]) || []).map(mapPaymentTokenToCreditCardTokenData);
    }

    return [];
  }, [paymentMethod, data?.customerPaymentTokens?.items, data?.creditCardTokens]);

  return (
    <CheckoutContext.Provider value={{ availableCreditCards, cart, paymentMethod, freeOrderCheckout }}>
      {children}
    </CheckoutContext.Provider>
  );
};
