import { FC } from 'react';
import { AlertsContext, useAlerts } from '~/utils/useAlerts';
import { Alerts } from '../components/structure/Alerts';

export const AlertsProvider: FC = ({ children }) => {
  const alertsContextValues = useAlerts();
  return (
    <AlertsContext.Provider value={alertsContextValues}>
      <Alerts />
      {children}
    </AlertsContext.Provider>
  );
};

export default AlertsProvider;
