import { Cart } from '~/operations';

/**
 * Calculate shipping by getting the value for the selected shipping method
 * for each shipping address in the cart. In case we don't have any associated yet,
 * preselect the price for the flat rate
 */
export const getFreightPrice = (cart) => {
  // TODO: Remove fixed default price of 50
  if (!cart?.shipping_addresses || cart.shipping_addresses.length === 0) return 50;

  return (cart?.shipping_addresses || []).reduce((prev, address) => {
    let value = address?.selected_shipping_method?.amount?.value;
    if (value === undefined) {
      const free = address.available_shipping_methods.find((sm) => sm.carrier_code === 'freeshipping');
      if (free) return prev; // when free is available, use it

      // Otherwise get from flat rate
      const flat = address.available_shipping_methods.find((sm) => sm.carrier_code === 'flatrate');
      value = flat !== undefined ? flat.amount.value : 0;
    }
    return prev + value;
  }, 0);
};

interface CartDiscounts {
  items: number;
  order: number;
  total: number;
}

export const getDiscounts = (cart?: Partial<Cart | null>): CartDiscounts => {
  const items =
    (cart?.items || [])?.reduce((prev, item) => {
      const qty = item?.quantity || 1;
      const total = item?.prices?.row_total?.value || 0;
      const sale = (item?.product?.price_range?.maximum_price?.regular_price?.value || 0) * qty;

      return prev + sale - total;
    }, 0) || 0;

  const order = (cart?.prices?.discounts || []).reduce((prev, discount) => prev + (discount?.amount.value || 0), 0);

  const total = items + order;

  return { items, order, total };
};

export const getMonthlyPrice = (cart) => cart?.prices?.subtotal_with_discount_excluding_tax?.value || 0;
export const getGrandTotal = (cart) => cart?.prices?.grand_total?.value || 0;
export const getSubtotal = (cart) => cart?.prices?.subtotal_excluding_tax?.value || 0;
