import { Maybe } from 'graphql/jsutils/Maybe';
import { createContext } from 'react';
import { CategoryTree, ProductInterface } from '~/operations';

interface SearchContextInteface {
  category?: CategoryTree;
  products?: any;
  loading: boolean;
  loadMore: () => any;
}

export const SearchContext = createContext<SearchContextInteface>({
  loading: false,
  loadMore: () => {},
});
