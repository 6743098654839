import { ApolloLink, InMemoryCache } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import cookie from 'js-cookie';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { GetCartDocument, GetCustomerDataDocument, GetCustomerDocument } from '~/operations';

let ErrorBoundary;
let apolloCache: InMemoryCache;
let bugsnagLoaded = false;

if (process.env.BUGSNAG_API_KEY && process.browser && !(Bugsnag as any)._client) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
      const user: any = {};
      const token = cookie.get(process.env.AUTH_COOKIE_KEY as string);
      if (token) {
        const jwt = token ? jwtDecode<JwtPayload>(token) : undefined;
        user.id = jwt?.sub;
        user.jwt = jwt;
      }
      user.cartId = cookie.get('cartID');
      user.persistentCartId = cookie.get('persistent_shopping_cart');
      if (apolloCache) {
        const cartData = getFromCache(GetCartDocument, { cartId: user.cartId });
        let customerData = getFromCache(GetCustomerDocument);
        if (!customerData) customerData = getFromCache(GetCustomerDataDocument);
        event.addMetadata('cache', {
          customer: customerData ? customerData.customer : null,
          cart: cartData ? cartData.cart : null,
        });
      }

      event.addMetadata('user', user);
    },
    onBreadcrumb: function (breadcrumb) {
      if (
        breadcrumb.type === 'request' &&
        (breadcrumb.metadata?.request?.includes('/graphql') || breadcrumb.metadata?.request?.includes('/_next'))
      ) {
        return false;
      }
    },
  });

  bugsnagLoaded = true;

  // Removing for the moment
  // ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary();
}

const getFromCache = (query, variables = {}) => {
  try {
    return apolloCache.readQuery<any>({ query, variables });
  } catch (e) {
    return null;
  }
};

const BugsnagErrorBoundary = ({ children }) => (ErrorBoundary ? <ErrorBoundary>{children}</ErrorBoundary> : children);

const getGraphQLData = ({ operationName, variables }) => ({
  operationName,
  variables: operationName === 'CreateCreditCardToken' ? '***REDACTED***' : variables || undefined,
});

export const bugsnagMiddleware = new ApolloLink((operation, forward) => {
  if (!process.env.BUGSNAG_API_KEY) return forward(operation);

  // else
  apolloCache = operation.getContext().cache;

  bugsnagLoaded && Bugsnag.leaveBreadcrumb('GraphQL Operation', getGraphQLData(operation), 'request');

  return forward(operation).map((data) => {
    if (data?.errors) {
      bugsnagLoaded &&
        Bugsnag.leaveBreadcrumb(
          'GraphQL Operation Error',
          { ...getGraphQLData(operation), errors: data.errors.map(({ path, message }) => ({ path, message })) },
          'error'
        );
    }

    return data;
  });
});

export default BugsnagErrorBoundary;
