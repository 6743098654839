import { createContext, useContext, useEffect, useReducer } from 'react';
import Cookie from 'js-cookie';

type Cookies = {
  [key: string]: string;
};

type InitialState = Cookies;

export type CookiesActions = {
  updateUserTokenCookie: (newToken: string) => void;
  updateCartIDCookie: (newCartID: string) => void;
  updateUserEmail: (newUserEmail: string) => void;
};

type ReducerActionTypes =
  | {
      type: 'UPDATE_USER_TOKEN_COOKIE';
      payload: string;
    }
  | {
      type: 'UPDATE_CART_ID_COOKIE';
      payload: string;
    }
  | {
      type: 'UPDATE_USER_EMAIL';
      payload: string;
    };

const initialState: InitialState = {
  cartID: '',
  token: '',
  userEmail: '',
};

const reducer = (state: InitialState = initialState, action: ReducerActionTypes) => {
  switch (action.type) {
    case 'UPDATE_USER_TOKEN_COOKIE': {
      return {
        ...state,
        token: action.payload,
      };
    }
    case 'UPDATE_CART_ID_COOKIE': {
      return {
        ...state,
        cartID: action.payload,
      };
    }
    case 'UPDATE_USER_EMAIL': {
      return {
        ...state,
        userEmail: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const useCookies = (cookies: Cookies) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...cookies,
    token: cookies[process.env.AUTH_COOKIE_KEY as string], // workaround while we don't refactor auth
  } as InitialState);

  return {
    state,
    actions: {
      updateUserTokenCookie: (newToken: string) => {
        Cookie.set(process.env.AUTH_COOKIE_KEY as string, newToken, { domain: process.env.AUTH_COOKIE_DOMAIN });
        dispatch({ type: 'UPDATE_USER_TOKEN_COOKIE', payload: newToken });
      },
      updateCartIDCookie: (newCartID: string) => {
        Cookie.set('cartID', newCartID);
        dispatch({ type: 'UPDATE_CART_ID_COOKIE', payload: newCartID });
      },
      updateUserEmail: (newUserEmail: string) => {
        Cookie.set('userEmail', newUserEmail);
        dispatch({ type: 'UPDATE_USER_EMAIL', payload: newUserEmail });
      },
    },
  };
};

export const CookiesContext = createContext<{ state: InitialState; actions: CookiesActions }>({
  state: initialState,
  actions: {
    updateUserTokenCookie: (newToken: string) => {},
    updateCartIDCookie: (newCartID: string) => {},
    updateUserEmail: (newUserEmail: string) => {},
  },
});

export const useCookiesContext = () => useContext(CookiesContext);
