import { FC, useEffect } from 'react';
import { useCart, CartContext } from '~/utils/useCart';

export const CartProvider: FC = ({ children }) => {
  const cartValues = useCart();

  useEffect(() => cartValues.actions.initCart(), []);

  return <CartContext.Provider value={cartValues}>{children}</CartContext.Provider>;
};

export default CartProvider;
