import '@objectedge/ziyou-storefront-ds/src/components/form/Select/Select.module.scss';
import '@objectedge/ziyou-storefront-ds/src/styles/common.scss';
import { JwtPayload } from 'jwt-decode';
import type { AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import { useEffect } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  AlertsProvider,
  AuthenticationController,
  CartProvider,
  CookieProvider,
  CustomApolloProvider,
} from '~/context';
import '~/styles/global.scss';
import fixWhatsappIcon from '~/utils/fixWhatsappIcon';
import { GTM } from '~/utils/gtm';
import { Cookies, parseCookies } from '~/utils/parseCookies';
import BugsnagErrorBoundary from '~/connectors/bugsnag';

interface ZiYouAppProps extends Omit<AppProps, 'pageProps'> {
  pageProps: AppProps['pageProps'] & { maintenance: Boolean; initialApolloState: any };
}

function ZiYouApp({ Component, pageProps, cookies }: ZiYouAppProps & { cookies: Cookies; jwt: JwtPayload }) {
  // Initiate GTM
  useEffect(() => {
    fixWhatsappIcon(Router.route);
    const handleRouteChange = (url: string) => {
      let _ = document.title;
      document.title = '';
      document.title = _;

      if (!process.browser || !localStorage) return;
      // Store the last 10 routes navigated on localstorage
      // This is meant to be used to move user back to specific pages by navigation component
      const track = JSON.parse(localStorage.getItem('previousUrl') || '[]');
      track.unshift(url);
      localStorage.setItem('previousUrl', JSON.stringify(track.slice(0, 10)));
      GTM.pageView(url);
      if (typeof window !== 'undefined') window.scrollTo(0, 0); // always scroll to top after pushing new route
    };

    Router.events.on('routeChangeComplete', handleRouteChange);
    Router.events.on('routeChangeComplete', fixWhatsappIcon);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
      Router.events.off('routeChangeComplete', fixWhatsappIcon);
    };
  }, []);

  const { asPath } = useRouter();
  const canonicalUrl = process.env.COMMERCE_URL + asPath.split('?')[0];

  if (pageProps.maintenance) return <Component {...pageProps} />;

  return (
    <BugsnagErrorBoundary>
      <CookieProvider cookies={cookies}>
        <CustomApolloProvider initialState={pageProps?.initialApolloState ?? {}}>
          <AlertsProvider>
            <CartProvider>
              <AuthenticationController>
                <Head>
                  {/* Adding here to avoid duplications as per link below
                https://github.com/vercel/next.js/issues/6919#issuecomment-500471583 */}
                  <meta
                    key="meta-viewport"
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1"
                  />
                  <link key="link-canonical" rel="canonical" href={canonicalUrl} />
                </Head>
                <Component {...pageProps} />
              </AuthenticationController>
            </CartProvider>
          </AlertsProvider>
        </CustomApolloProvider>
      </CookieProvider>
    </BugsnagErrorBoundary>
  );
}

ZiYouApp.getInitialProps = async (appContext) => {
  const { ctx } = appContext;
  return {
    ...(await App.getInitialProps(appContext)),
    cookies: parseCookies(ctx),
  };
};

export default ZiYouApp;
