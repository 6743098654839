var masks = {
    cpf: [
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/
    ],
    cnpj: [
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/
    ],
    phone: [
        "(",
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ],
    cep: [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/
    ],
    cardNumber: [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/, 
    ],
    cardNumberAmex: [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ],
    cardNumberHipercard: [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/, 
    ],
    cardCVC: [
        /\d/,
        /\d/,
        /\d/
    ],
    cardCVCAmex: [
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ],
    date: [
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ],
    registration: [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ],
    cardExpireDate: [
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ]
};
export var getCardMask = function(issuer) {
    switch(issuer){
        case "hipercard":
            return masks.cardNumberHipercard;
        case "amex":
            return masks.cardNumberAmex;
        default:
            return masks.cardNumber;
    }
};
export var getCardCVCMask = function(issuer) {
    return [
        "amex",
        "discover"
    ].includes(issuer) ? masks.cardCVCAmex : masks.cardCVC;
};
export var getCardCVCLength = function(issuer) {
    return [
        "amex",
        "discover"
    ].includes(issuer) ? 4 : 3;
};
export default masks;
