import { FC } from 'react';
import { Cookies } from '../utils/parseCookies';
import { CookiesContext, useCookies } from '../utils/useCookies';

export const CookieProvider: FC<{ cookies: Cookies }> = ({ children, cookies }) => {
  const cookieContextValues = useCookies(cookies);
  return <CookiesContext.Provider value={cookieContextValues}>{children}</CookiesContext.Provider>;
};

export default CookieProvider;
