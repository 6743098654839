import cookie from 'cookie';
import { NextPageContext } from 'next';

export type Cookies = {
  [key: string]: string;
};

export function parseCookies(ctx: NextPageContext) {
  return cookie.parse(ctx?.req?.headers?.cookie ?? '');
}
