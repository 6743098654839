import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/dist/client/router';
import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import {
  CustomizableOptionInput,
  Money,
  useAddConfigurableProductToCartMutation,
  useAddSimpleProductToCartMutation,
  useCreateEmptyCartMutation,
  useGetCartLazyQuery,
  useMergeCartsMutation,
  useRemoveItemFromCartMutation,
  useUpdateCartItemsMutation,
  SelectedCustomizableOption,
} from '~/operations';
import GTM from './gtm';
import parseMinimumRequirements, { MinimumRequirement } from './product';
import { useAlertsContext } from './useAlerts';
import { useCookiesContext } from './useCookies';

const GTMCB = (sku, qty) => (cart) => {
  const item = (cart?.items || []).find((i) => i?.product.sku === sku);
  item && GTM.addToCart(item, qty);
};

// TODO: Make the product type more DRY
export type Product =
  | {
      productType: 'simple';
      id: number;
      sku: string;
      name: string;
      type: string;
      image: string;
      value: number;
      url_key: string;
      parent_url_key: string;
      promotionalValue?: number;
      finalPrice?: number;
      cartQty: number;
      stockQty: number;
      details: Array<MinimumRequirement>;
      status: 'active' | 'inactive';
      customizable_options: Array<SelectedCustomizableOption>;
      hasSelectedPlan: boolean;
    }
  | {
      productType: 'configurable';
      id: number;
      sku: string;
      name: string;
      type: string;
      image: string;
      value: number;
      url_key: string;
      parent_url_key: string;
      promotionalValue?: number;
      finalPrice?: number;
      cartQty: number;
      stockQty: number;
      details: Array<MinimumRequirement>;
      status: 'active' | 'inactive';
      customizable_options: Array<SelectedCustomizableOption>;
      hasSelectedPlan: boolean;
    }
  | {
      productType: 'virtual';
      id: number;
      sku: string;
      name: string;
      type: string;
      image: string;
      value: number;
      url_key: string;
      parent_url_key: string;
      promotionalValue?: number;
      finalPrice?: number;
      cartQty: number;
      stockQty: number;
      details: Array<MinimumRequirement>;
      status: 'active' | 'inactive';
      customizable_options: Array<SelectedCustomizableOption>;
      hasSelectedPlan: boolean;
    };

type CartLoadings = {
  initCart: boolean;
  mergeCart: boolean;
  addToCart: boolean;
  increaseProductQty: number[]; // List of product id that changed the qty
  decreaseProductQty: number[]; // List of product id that changed the qty
  changeProductQty: boolean;
  lazyChangeProductQty: number[]; // List of product id that changed the qty
  removeFromCart: number[];
};

type InitialState = {
  id: string;
  loadingCart: boolean;
  products: Product[];
  discounts?: number;
  freight?: number;
  loadings: CartLoadings;
};

type Actions = {
  initCart: (reset?: boolean) => void;
  mergeCart: (loggedUserToken: string) => void;
  addToCart: ({ payload, successCallback, errorCallback }: AddToCartProps) => void;
  increaseProductQty: (productID: number) => void;
  decreaseProductQty: (productID: number) => void;
  changeProductQty: (productID: number, newQty: number) => void;
  lazyChangeProductQty: (productID: number, newQty: number) => void;
  removeFromCart: (productID: number) => void;
  getTotalItemsInTheCart: () => number;
  hasNonVirtualProduct: () => boolean;
  hasZeroAmountOrder: () => boolean;
  resetCart: () => void;
  isInSoftLoginState: () => Boolean;
};

type ReducerActionTypes =
  | {
      type: 'INIT_CART';
      payload: {
        id: string;
        products: Product[];
      };
    }
  | {
      type: 'MERGE_CART';
      payload: {
        id: string;
        products: Product[];
      };
    }
  | {
      type: 'ADD_TO_CART';
      payload: Product;
    }
  | {
      type: 'UPDATE_ALL_PRODUCTS';
      payload: Product[];
    }
  | {
      type: 'INCREASE_PRODUCT_QTY';
      payload: {
        id: number;
      };
    }
  | {
      type: 'DECREASE_PRODUCT_QTY';
      payload: {
        id: number;
      };
    }
  | {
      type: 'REMOVE_FROM_CART';
      payload: {
        id: number;
      };
    }
  | {
      type: 'CHANGE_PRODUCT_QTY';
      payload: {
        id: number;
        newQty: number;
      };
    }
  | {
      type: 'RESET_CART';
    };

const initialState: InitialState = {
  id: '',
  loadingCart: true,
  products: [],
  discounts: 0,
  freight: 0,
  loadings: {
    initCart: false,
    mergeCart: false,
    addToCart: false,
    increaseProductQty: [],
    decreaseProductQty: [],
    changeProductQty: false,
    lazyChangeProductQty: [],
    removeFromCart: [],
  },
};

const reducer = (state: InitialState = initialState, action: ReducerActionTypes) => {
  switch (action.type) {
    case 'INIT_CART': {
      return {
        ...state,
        id: action.payload.id,
        loadingCart: false,
        products: action.payload.products,
      };
    }
    case 'MERGE_CART': {
      return {
        ...state,
        id: action.payload.id,
        products: action.payload.products,
      };
    }
    case 'ADD_TO_CART': {
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    }
    case 'UPDATE_ALL_PRODUCTS': {
      return {
        ...state,
        products: action.payload,
      };
    }
    case 'INCREASE_PRODUCT_QTY': {
      return {
        ...state,
        products: state.products.map((product) => {
          if (product.id !== action.payload.id) return product;
          return {
            ...product,
            cartQty: product.cartQty + 1,
          };
        }),
      };
    }
    case 'DECREASE_PRODUCT_QTY': {
      return {
        ...state,
        products: state.products.map((product) => {
          if (product.id !== action.payload.id) return product;
          return {
            ...product,
            cartQty: product.cartQty === 1 ? product.cartQty : product.cartQty - 1,
          };
        }),
      };
    }
    case 'CHANGE_PRODUCT_QTY': {
      return {
        ...state,
        products: state.products.map((product) => {
          if (product.id !== action.payload.id) return product;
          return {
            ...product,
            cartQty: action.payload.newQty < 0 || isNaN(action.payload.newQty) ? 1 : action.payload.newQty,
          };
        }),
      };
    }
    case 'REMOVE_FROM_CART': {
      return {
        ...state,
        products: state.products.filter((product) => product.id !== action.payload.id),
      };
    }
    case 'RESET_CART': {
      return { ...initialState, loadingCart: false };
    }
    default: {
      return state;
    }
  }
};

type AddToCartProps = {
  payload:
    | {
        qty: number;
        sku: string;
        plan?: CustomizableOptionInput;
      }
    | {
        qty: number;
        parentSku: string;
        skuFromEspecificOption: string;
        plan?: CustomizableOptionInput;
      };
  successCallback?: () => void;
  errorCallback?: () => void;
  reset?: boolean;
};

// TODO: Add better typing
const formatProducts = (items: any[]) =>
  items
    .filter((i) => i !== null)
    .map((item: any) => {
      const { product, customizable_options, prices } = item;
      const { price_range } = product;

      const hasSelectedPlan: boolean = !!customizable_options?.find((option) => option?.label === 'Plano');
      const listPrice: Money | undefined = price_range?.maximum_price?.regular_price;
      const salePrice: Money | undefined = prices?.price;
      const hasSalePrice: boolean = !!salePrice?.value && !!listPrice?.value && !!(salePrice?.value < listPrice?.value);

      const productTypeText = (() => {
        if (product.color && product.motor_type) return `${product.color} | ${product.motor_type}`;
        if (product.color && !product.motor_type) return product.color;
        if (!product.color && product.motor_type) return product.motor_type;
        return '';
      })();

      return {
        id: parseInt(item.id),
        sku: product.sku,
        productType:
          product.__typename === 'SimpleProduct'
            ? 'simple'
            : product.__typename === 'VirtualProduct'
            ? 'virtual'
            : 'configurable',
        name: product.name,
        type: productTypeText,
        image: product.image.urls.medium,
        value: listPrice?.value ?? 0,
        url_key: product.url_key,
        parent_url_key: product.parent_url_key,
        ...(hasSalePrice && { promotionalValue: salePrice?.value ?? 0 }),
        cartQty: item.quantity,
        stockQty: product?.stock_level ?? 0,
        details: parseMinimumRequirements(product.minimum_requirements, { cleanSizes: true }),
        status: product?.stock_level > 0 ? 'active' : 'inactive',
        customizable_options,
        hasSelectedPlan,
      } as Product;
    });

// Check if the error was caused by the soft login cart being expired. To identify this error we check
// for the message "The current user cannot perform operations on cart %cartID", since there's no other identifier.
const isSoftLoginCartExpired = (data) => {
  return (
    !!data?.errors?.length &&
    !!data.errors.find((error) => error?.message?.indexOf('The current user cannot perform operations on cart') !== -1)
  );
};

export const useCart = () => {
  const { cache } = useApolloClient();
  const { state: cookieValues, actions: cookieActions } = useCookiesContext();
  const { actions: alertActions } = useAlertsContext();
  const router = useRouter();
  const { cartID, token: userToken } = cookieValues;
  const [state, dispatch] = useReducer(reducer, { ...initialState, id: cartID } as InitialState);

  // When soft login expires and there's an error in cart operations, reset cart and redirect to login page
  const handleSoftLoginExpiredError = useCallback(() => {
    cookieActions.updateCartIDCookie('');
    cookieActions.updateUserEmail('');
    dispatch({ type: 'RESET_CART' });
    createEmptyCart();
    alertActions.addDangerAlert('Sua sessão expirou, por favor, logar novamente');
    router.push('/login');
  }, [dispatch, alertActions, cookieActions, router]);

  // Create Empty Cart
  const [createEmptyCart] = useCreateEmptyCartMutation({
    onCompleted: (data) => {
      if (!data.createEmptyCart) {
        alertActions.addDangerAlert('Não foi possível criar o seu carrinho de compras, tente novamente mais tarde');
        return;
      }

      dispatch({ type: 'INIT_CART', payload: { id: data.createEmptyCart, products: [] } });
    },
    onError: (error) => console.error('error createEmptyCart:', error),
  });

  useEffect(() => {
    cookieActions.updateCartIDCookie(state.id);
  }, [state.id]);

  // Merge Carts
  const [mergeCarts] = useMergeCartsMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!data?.mergeCarts) {
        console.error('Erro ao fazer merge');
        return;
      }

      cache.evict({ id: cache.identify({ __typename: 'Cart', id: data.mergeCarts.id }) });

      dispatch({
        type: 'INIT_CART',
        payload: {
          id: data.mergeCarts.id,
          products: formatProducts(data.mergeCarts.items || []),
        },
      });
    },
    onError: (error) => console.error('error mergeCarts:', error),
    errorPolicy: 'ignore',
  });

  // Get Cart by ID
  const [getCartById, { data: customerData }] = useGetCartLazyQuery({
    errorPolicy: 'ignore',
    onError: (error) => console.error('error getCartById:', error),
  });

  // Add Configurable Product to Cart
  const [addConfigurableProductToCart] = useAddConfigurableProductToCartMutation({
    onCompleted: (data: any) => {
      if (!data?.addConfigurableProductsToCart) {
        return;
      }

      dispatch({
        type: 'UPDATE_ALL_PRODUCTS',
        payload: formatProducts(data.addConfigurableProductsToCart.cart.items),
      });
    },
    errorPolicy: 'ignore',
    onError: (error) => {
      console.error('error addConfigurableProductToCart:', error);
    },
  });

  // Add Simple Product to Cart
  const [addSimpleProductToCart] = useAddSimpleProductToCartMutation({
    onCompleted: (data: any) => {
      if (!data?.addSimpleProductsToCart) {
        return;
      }

      dispatch({
        type: 'UPDATE_ALL_PRODUCTS',
        payload: formatProducts(data.addSimpleProductsToCart.cart.items),
      });
    },
    errorPolicy: 'ignore',
    onError: (error) => {
      console.error('error addSimpleProductToCart:', error);
    },
  });

  // Update Product Qty from Cart
  const [updateProductQtyInCart] = useUpdateCartItemsMutation({
    onCompleted: (data: any) => {
      if (!data?.updateCartItems) {
        return;
      }

      dispatch({
        type: 'UPDATE_ALL_PRODUCTS',
        payload: formatProducts(data.updateCartItems.cart.items),
      });
    },
    onError: (error) => {
      console.error('error updateProductQtyInCart:', error);
      if (error.message.includes('The requested qty is not available')) {
        alertActions.addDangerAlert('Produto não contém mais estoque');
      }
    },
  });

  // Remove Product from Cart
  const [removeProductFromCart] = useRemoveItemFromCartMutation({
    onCompleted: (data: any) => {
      if (!data?.removeItemFromCart) {
        return;
      }

      dispatch({
        type: 'UPDATE_ALL_PRODUCTS',
        payload: formatProducts(data.removeItemFromCart.cart.items),
      });
    },
    errorPolicy: 'ignore',
    onError: (error) => {
      console.error('error removeProductFromCart:', error);
    },
  });

  return {
    state,
    actions: {
      initCart: (reset: boolean = false) => {
        if (!state.id || reset) {
          createEmptyCart().then((data) => {
            if (isSoftLoginCartExpired(data)) {
              handleSoftLoginExpiredError();
              return;
            }
          });
          return;
        }

        getCartById({ variables: { cartId: state.id } }).then(({ data }) => {
          if (!data?.cart) {
            dispatch({ type: 'RESET_CART' });
            createEmptyCart();
            return;
          }
          dispatch({
            type: 'INIT_CART',
            payload: {
              id: data.cart.id,
              products: formatProducts(data.cart.items || []),
            },
          });
        });
      },
      mergeCart: () => {
        mergeCarts({
          variables: {
            sourceCartID: state.id,
          },
        });
      },
      addToCart: ({ payload, successCallback, errorCallback, reset = false }: AddToCartProps) => {
        if ('sku' in payload) {
          addSimpleProductToCart({
            variables: {
              cartId: state.id,
              productPayload: {
                quantity: payload.qty,
                sku: payload.sku,
              },
              option: payload.plan,
              reset,
            },
          })
            .then((data) => {
              if (isSoftLoginCartExpired(data)) {
                handleSoftLoginExpiredError();
                return;
              }
              GTMCB(payload.sku, payload.qty)(data.data?.addSimpleProductsToCart?.cart);
              successCallback && successCallback();
            })
            .catch(() => errorCallback && errorCallback());
          return;
        }

        addConfigurableProductToCart({
          variables: {
            cartId: state.id,
            productPayload: {
              data: {
                quantity: payload.qty,
                sku: payload.skuFromEspecificOption,
              },
              customizable_options: payload.plan ? [payload.plan] : undefined,
              // parent_sku: payload.parentSku,
            },
          },
        })
          .then((data) => {
            if (isSoftLoginCartExpired(data)) {
              handleSoftLoginExpiredError();
              return;
            }
            GTMCB(payload.skuFromEspecificOption, payload.qty)(data.data?.addConfigurableProductsToCart?.cart);
            successCallback && successCallback();
          })
          .catch((error) => {
            errorCallback && errorCallback();
          });
      },
      increaseProductQty: (productID: number) => {
        const product = state.products.find((product) => product.id === productID);
        if (!product) return;

        updateProductQtyInCart({
          variables: {
            cartId: state.id,
            productId: productID,
            newQty: product.cartQty + 1,
          },
        }).then((data) => {
          if (isSoftLoginCartExpired(data)) {
            handleSoftLoginExpiredError();
            return;
          }
          GTMCB(product.sku, 1)(data.data?.updateCartItems?.cart);
        });
      },
      decreaseProductQty: (productID: number) => {
        const product = state.products.find((product) => product.id === productID);
        if (!product) return;

        updateProductQtyInCart({
          variables: {
            cartId: state.id,
            productId: productID,
            newQty: product.cartQty === 1 ? product.cartQty : product.cartQty - 1,
          },
        }).then((data) => {
          if (isSoftLoginCartExpired(data)) {
            handleSoftLoginExpiredError();
            return;
          }
          GTM.removeFromCart(product, 1);
        });
      },
      changeProductQty: (productID: number, newQty: number) => {
        dispatch({ type: 'CHANGE_PRODUCT_QTY', payload: { id: productID, newQty } });
      },
      lazyChangeProductQty: (productId: number, qty: number) => {
        const product = state.products.find((product) => product.id === productId);
        if (!product) return;

        const newQty = qty <= 0 ? 1 : qty;
        updateProductQtyInCart({
          variables: {
            cartId: state.id,
            productId,
            newQty,
          },
        }).then((data) => {
          if (isSoftLoginCartExpired(data)) {
            handleSoftLoginExpiredError();
            return;
          }
        });
      },
      removeFromCart: (productID: number) => {
        const product = state.products.find((p) => p.id === productID);
        removeProductFromCart({
          variables: {
            cartId: state.id,
            productId: productID,
          },
        }).then((data) => {
          if (isSoftLoginCartExpired(data)) {
            handleSoftLoginExpiredError();
            return;
          }
          product && GTM.removeFromCart(product);
        });
      },
      getTotalItemsInTheCart: () =>
        state.products.filter((p) => p.sku !== 'club').reduce((acc, product) => acc + product.cartQty, 0),
      hasNonVirtualProduct: () => state?.products.some((element) => element?.productType !== 'virtual'),
      hasZeroAmountOrder: () =>
        Array.isArray(customerData?.cart?.available_payment_methods) &&
        (customerData?.cart?.available_payment_methods.some(
          (element) => element?.code === 'free' || element?.code === 'payment_service_free'
        ) ||
          false),
      resetCart: function () {
        dispatch({ type: 'RESET_CART' });
        this.initCart(true);
      },
      isInSoftLoginState: () => !!cartID && !userToken,
    },
  };
};

export const CartContext = createContext<{ state: InitialState; actions: Actions }>({
  state: initialState,
  actions: {
    initCart: (reset?: boolean) => {},
    mergeCart: (loggedUserToken: string) => {},
    addToCart: ({ payload, successCallback, errorCallback }: AddToCartProps) => {},
    increaseProductQty: (productID: number) => {},
    decreaseProductQty: (productID: number) => {},
    changeProductQty: (productID: number, newQty: number) => {},
    lazyChangeProductQty: (productID: number, newQty: number) => {},
    removeFromCart: (productID: number) => {},
    getTotalItemsInTheCart: () => 0,
    hasNonVirtualProduct: () => false,
    hasZeroAmountOrder: () => false,
    resetCart: () => {},
    isInSoftLoginState: () => false,
  },
});

export const useCartContext = () => useContext(CartContext);
